import polyline from '@mapbox/polyline';
import * as bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import '../css/button.css';
import '../css/card.css';
import '../css/viewshed.css';

import backcountryStylesheet from '../assets/backcountry-terrain-x.json';
import huntStylesheet from '../assets/hunt-terrain-x.json';
import offroadStylesheet from '../assets/offroad-terrain-x.json';
import './web-components/account/account-button-components.js';
import './web-components/account/forgot-password-modal-component.js';
import './web-components/account/logout-modal-component.js';
import './web-components/account/send-feedback-button-component.js';
import './web-components/account/upgrade-modal-component.js';
import './web-components/app-component.js';
import './web-components/cards/flyby-card-component.js';
import './web-components/cards/waypoint-create-card-component.js';
import './web-components/controls/basemap-toggle.js';
import './web-components/controls/go-to-location-control-component.js';
import './web-components/controls/zoom-control-component.js';
import './web-components/search-box-component.js';
import './web-components/terrain-tools/small-tools-container-component.js';
import './web-components/terrain-tools/terrain-tools-container-component.js';
import { setViewshedState } from './web-components/terrain-tools/viewshed-button-component.js';

import appService, { APP_BACKCOUNTRY, APP_HUNT, APP_OFFROAD } from '../js/services/app-service.js';
import markupService from '../js/services/markup-service.js';
import {
  initPerformance,
  logPerformanceMetric,
  sendSessionMetrics,
} from './services/performance-service.js';
import profileService from './services/profile-service.js';
import * as recording from './services/recording-service.js';
import stateService from './services/state-service.js';
import supergraphService from './services/supergraph-service.js';

import './prebid-ads.js';
import * as marketing from './utilities/marketing.js';
import * as sentry from './utilities/sentry.js';
import { removeToken, retrieveToken, setToken } from './utilities/token.js';
import * as login from './web-components/account/login-modal-component.js';

import loadOnyx from '@onxmaps/onyx';

import { ElevationRangeLayer } from './layers/ElevationRangeLayer.js';
import { IntersectLayer } from './layers/IntersectLayer.js';
import { SlopeAngleLayer } from './layers/SlopeAngleLayer.js';
import { SlopeAspectLayer } from './layers/SlopeAspectLayer.js';
import { ViewshedLayer } from './layers/ViewshedLayer.js';

const environment = 'production'
const waypointsUrl = `https://api.${environment}.onxmaps.com/v1/markups/waypoints`
const routesUrl = `https://api.${environment}.onxmaps.com/v1/routing/routes`
const tracksUrl = `https://api.${environment}.onxmaps.com/v1/markups/tracks`
const featuredTrailsUrl = `https://api.${environment}.onxmaps.com/v1/supergraph/`

const FEET_TO_METERS = 0.3048
const EARTH_RADIUS_METERS = 6378137

let onXUserJwt = null

let viewshedEnabled = false
let followCursor = true

let onyx = null

const basemaps = ['aerial', 'topo']

let trailId = null
let focusedPathId = null

let viewshedLayer = null
let intersectLayer = null
let elevationRangeLayer = null
let slopeAngleLayer = null
let slopeAspectLayer = null

let flybyDuration = 30000.0
let flybyRadiusGuide = 1000.0
let flybyPitchGuide = 60.0

const mousePosition = { x: 0, y: 0 }

function isUUID(str) {
  const lower = str.toLowerCase()
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(lower);
}

async function main() {
  if (processAuthTokenMessage()) return

  // initialize mparticle, amplitude, and sentry
  sentry.initialize()
  marketing.initialize()

  document.addEventListener('on-login-success', initTerrainX, true)

  document.addEventListener(
    'on-need-upgrade',
    () => {
      bootstrap.Modal.getOrCreateInstance(
        document.getElementById('modal-upgrade-form')
      ).show()
    },
    true
  )

  document.addEventListener(
    'on-logout',
    () => {
      removeToken()
      clearLastCameraState()
      location.reload()
      profileService.clearCurrentUserProfile()
    },
    true
  )

  window.addEventListener('beforeunload', () => {
    marketing.logEvent('terrain_x_closed')

    // logged in?
    if (!retrieveToken()) {
      return
    }

    // send performance metrics
    sendSessionMetrics()

    // get camera state and store in local storage
    const cameraState = onyx?.getCameraState(onyx.MAIN_VIEWPORT_ID) ?? null
    if (cameraState)
      localStorage.setItem('lastCameraState', JSON.stringify(cameraState))
  })

  login.initialize()
}

async function fetchWaypoints(app) {
  // fetch waypoints in pages using cursor
  const PAGE_SIZE = 500
  let cursor

  while (cursor !== null) {
    const url = cursor
      ? `${waypointsUrl}?limit=${PAGE_SIZE}&cursor=${cursor}`
      : `${waypointsUrl}?limit=${PAGE_SIZE}`

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'ONX-Application-ID': app,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${onXUserJwt}`,
      },
    })

    const data = await response.json()

    if ('errors' in data) {
      throw new Error(data.errors)
    }

    data.forEach((d) => {
      // add waypoint to map
      const coords = d.geo_json.geometry.coordinates
      const color = d.color
        ? {
            r: d.color[0] / 255.0,
            g: d.color[1] / 255.0,
            b: d.color[2] / 255.0,
            a: d.color[3],
          }
        : { r: 1.0, g: 0.2, b: 0.0, a: 1.0 }
      onyx.addWaypoint(d.uuid, { lon: coords[0], lat: coords[1] }, color)

      // add waypoint to service
      markupService.addWaypoint(d)
    })

    // get cursor for next request
    cursor = response?.headers?.get('onx-page-cursor') ?? null
  }
}

async function fetchRoutes(app) {
  // fetch routes in pages using cursor
  const PAGE_SIZE = 50
  let cursor

  while (cursor !== null) {
    const url = cursor
      ? `${routesUrl}?excludeSteps&page[size]=${PAGE_SIZE}&cursor=${cursor}`
      : `${routesUrl}?excludeSteps&page[size]=${PAGE_SIZE}`

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'ONX-Application-ID': app,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${onXUserJwt}`,
      },
    })

    const data = await response.json()

    if ('errors' in data) {
      throw new Error(data.errors)
    }

    data.data.forEach((d) => {
      // add route to markup service
      markupService.addRoute(d)
    })

    // get cursor for next request
    cursor = response?.headers?.get('onx-page-cursor') ?? null
  }

  document.dispatchEvent(new CustomEvent('routes-loaded'))
}

async function fetchTracks(app) {
  // fetch tracks in pages using cursor
  const PAGE_SIZE = 500
  let cursor

  while (cursor !== null) {
    const url = cursor
      ? `${tracksUrl}?limit=${PAGE_SIZE}&cursor=${cursor}`
      : `${tracksUrl}?limit=${PAGE_SIZE}`

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'ONX-Application-ID': app,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${onXUserJwt}`,
      },
    })

    const data = await response.json()

    if ('errors' in data) {
      throw new Error(data.errors)
    }

    data.forEach((d) => {
      // add track to markup service
      markupService.addTrack(d)
    })

    // get cursor for next request
    cursor = response?.headers?.get('onx-page-cursor') ?? null
  }

  document.dispatchEvent(new CustomEvent('tracks-loaded'))
}

async function fetchTrails(app) {
  if (!trailId) {
    return
  }

  const url = featuredTrailsUrl
  const id = trailId
  if (isUUID(id)) { // request via RichPlace
    const query = `query byFilter($id: String!) { place(id: $id) { id, name, attributes { difficultySymbol, technicalRating } geometry { type coordinates } } }`

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'ONX-Application-ID': app,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${onXUserJwt}`
      },
      referrerPolicy: 'strict-origin-when-cross-origin',
      mode: 'cors',
      body: JSON.stringify({
        operationName: "byFilter",
        query: query,
        variables: { id: id }
      })
    })

    const data = await response.json()
    const trail = data.data.place
    supergraphService.addTrail(trail)
    trailId = trail.id
  } else { // request via offroadRoutes
    const query = `query offroadRouteQuery { offroadRoutes(filter: { id: "${id}" }) { id, name, length, geometry } }`

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'ONX-Application-ID': app,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${onXUserJwt}`
      },
      referrerPolicy: 'strict-origin-when-cross-origin',
      mode: 'cors',
      body: JSON.stringify({
        operationName: "offroadRouteQuery",
        query: query,
        variables: {}
      })
    })

    const data = await response.json()

    data.data.offroadRoutes.forEach((d) => {
      // add trail to supergraph service
      supergraphService.addTrail(d)
      trailId = d.id
    })
  }
  

  focusOnPath(id, processTrail(supergraphService.getTrails()[trailId]), 0)

  document.dispatchEvent(new CustomEvent('trails-loaded'))
}

/**
 * Set the initial camera state, either from url hash or local storage
 */
function initCameraState() {
  // get hash camera state ("lat/lon/height/heading/pitch")
  const hash = window?.location?.hash?.slice(1)?.split('/')

  if (!hash) {
    return
  }

  const cameraState = {
    position: {
      lat: Number(hash[0]),
      lon: Number(hash[1]),
      elevation: Number(hash[2]),
    },
    heading: Number(hash[3]),
    pitch: Number(hash[4])
  }

  if (validateCameraState(cameraState)) {
    // init camera with hash camera state if valid
    onyx.setCameraState(onyx.MAIN_VIEWPORT_ID, cameraState)
  } else {
    // otherwise use saved camera state from local storage
    goToLastCameraState()
  }
}

/**
 * Read a query param with a trail id to request from the supergraph
 */
function initTrailId() {
  const params = new URLSearchParams(window.location.search)
  const id = params.get('trail')
  if (id) {
    trailId = id
  }
}

/**
 * Sets the map's camera state to the user's last viewed location saved in local storage.
 * If there is no saved location, the camera state is not set and the map will load with default parameters.
 */
function goToLastCameraState() {
  // check for saved location in browser storage
  const state = localStorage.getItem('lastCameraState')

  if (!state) {
    return
  }

  let parsedState = JSON.parse(state)

  // validate location
  if (!validateCameraState(parsedState)) {
    logPerformanceMetric('last_camera_state_invalid', onyx.getCameraState(onyx.MAIN_VIEWPORT_ID))
    return
  }

  // update the map's camera state with the saved location
  onyx.setCameraState(onyx.MAIN_VIEWPORT_ID, parsedState)
}

/**
 * Checks if a given camera state is valid
 * @param {Object} state - camera state to validate
 * @returns {Boolean} if the given camera state is valid
 */
function validateCameraState(state) {
  const valid =
    !isNaN(state.heading) &&
    isValidElevation(state.position?.elevation || state.focus?.elevation) &&
    isValidPitch(state.pitch) &&
    isValidLatitude(state.position?.lat || state.focus?.lat) &&
    isValidLongitude(state.position?.lon || state.focus?.lon)
  return valid
}

/**
 * Checks if a latitude value is valid
 * @param {Number} lat - given latitude
 * @returns {Boolean}
 */
function isValidLatitude(lat) {
  return !isNaN(lat) && lat <= 90 && lat >= -90
}

/**
 * Checks if a longitude value is valid
 * @param {Number} lon - given longitude
 * @returns {Boolean}
 */
function isValidLongitude(lon) {
  return !isNaN(lon) && lon <= 180 && lon >= -180
}

/**
 * Checks if a pitch value is valid
 * 1.5708 is 90 degrees in radians
 * @param {Number} pitch - given pitch
 * @returns {Boolean}
 */
function isValidPitch(pitch) {
  return !isNaN(pitch) && pitch >= 0 && pitch <= 90
}

/**
 * Checks if a elevation value is valid
 * @param {Number} elevation - given elevation
 * @returns {Boolean}
 */
function isValidElevation(elevation) {
  return !isNaN(elevation) && elevation >= 0
}

/**
 * Remove the saved last camera state from storage
 */
function clearLastCameraState() {
  localStorage.removeItem('lastCameraState')
}

async function initOnyx() {
  onyx = await loadOnyx()
  await onyx.setProdToken(onXUserJwt)
  onyx.initialize()
  window.addEventListener('close', onyx.shutdown)
  initPerformance(onyx)
  onyx.toggleDebugUI(false)
  onyx.setPostProcessParams(0.1)

  appService.onyx = onyx
}

function loadStyleForApp(app) {
  let stylesheetJson = null
  if (app == APP_HUNT) {
    console.log('Loading hunt stylesheet')
    stylesheetJson = huntStylesheet
  } else if (app == APP_BACKCOUNTRY ){
    console.log('Loading backcountry stylesheet')
    stylesheetJson = backcountryStylesheet
  } else if (app == APP_OFFROAD ){
    console.log('Loading offroad stylesheet')
    stylesheetJson = offroadStylesheet
  }
  for (const sourceKey in stylesheetJson.sources) {
    for (const tile in stylesheetJson.sources[sourceKey].tiles) {
      stylesheetJson.sources[sourceKey].tiles[tile] += `?token=${onXUserJwt}`
    }
  }
  onyx.setStyle(onyx.MAIN_VIEWPORT_ID, JSON.stringify(stylesheetJson))
}

function flyTo(lon, lat) {
  const dst = {
    focus: { lon: lon, lat: lat },
    heading: 0,
    pitch: 0,
    radius: 10000
  }
  onyx.flyToLookState2D(onyx.MAIN_VIEWPORT_ID, dst, 3000)
}

function selectBasemap(basemapIdx) {
  for (let i = 0; i < basemaps.length; i++) {
    onyx.toggleLayerGroup(onyx.MAIN_VIEWPORT_ID, basemaps[i], i === basemapIdx ? 'visible' : 'none')
  }
}

function initLayers() {
  console.assert(onyx)
  console.assert(stateService)
  
  const lastBasemapIdx = localStorage.getItem('lastBasemapIdx')
  if (lastBasemapIdx) {
    const basemapIdx = Number(lastBasemapIdx)
    stateService.setState('basemapIdx', basemapIdx)
    selectBasemap(basemapIdx)
  }
  
  intersectLayer = new IntersectLayer(onyx)
  elevationRangeLayer = new ElevationRangeLayer(onyx, intersectLayer)
  slopeAngleLayer = new SlopeAngleLayer(onyx, intersectLayer)
  slopeAspectLayer = new SlopeAspectLayer(onyx, intersectLayer)
  viewshedLayer = new ViewshedLayer(onyx)
  updateElevationRangeFromState()
  updateSlopeAngleFromState()
  updateSlopeAspectFromState()
  updateIntersectFromState()
}

function updateElevationRangeFromState() {
  const elevationRange = stateService.getState('heightBandFeet')
  elevationRangeLayer.update(elevationRange.min * FEET_TO_METERS, elevationRange.max * FEET_TO_METERS)
}

function updateSlopeAngleFromState() {
  const angleDegrees = stateService.getState('slopeAngleDegrees')
  slopeAngleLayer.update(angleDegrees.min, angleDegrees.max)
}

function updateSlopeAspectFromState() {
  const aspectRanges = stateService.getState('slopeAspectDegrees')
  slopeAspectLayer.update(aspectRanges.map(r => [r.start, r.end]))
}

function updateIntersectFromState() {
  const intersectEnabled = stateService.getState('intersectEnabled')
  if (intersectEnabled) {
    intersectLayer.toggle(true)
  } else {
    intersectLayer.toggle(false)
    elevationRangeLayer.toggle(stateService.getState('heightBandEnabled'))
    slopeAngleLayer.toggle(stateService.getState('slopeAngleEnabled'))
    slopeAspectLayer.toggle(stateService.getState('slopeAspectEnabled'))
  }
}

/**
 * Attempts to process a token message, if present. Returns true if a token message was processed, false otherwise.
 * @returns {Boolean} if a token message was processed.
 */
function processAuthTokenMessage() {
  const urlParams = new URLSearchParams(window?.location?.search)
  const messageParam = urlParams.get('message')
  if (!messageParam) return false

  // check for token message from webmap when the message query param is present
  // listen for user token sent from webmap
  window.addEventListener(
    'message',
    (event) => {
      // IMPORTANT - always have origin specified to maintain security. otherwise we could act on events sent from other domains open in the browser
      if (event.origin === 'https://webmap.onxmaps.com') {
        const JWT = event.data?.token
        if (JWT) {
          setToken(JWT)
        } else {
          // remove stored token
          removeToken()
          clearLastCameraState()
        }
      }
    },
    false
  )

  // If the message query param is present, then don't load the rest of the app
  // FUTURE create separate pages in the app to achieve this code separation rather than checking pathname here
  return true
}

function computeApp() {
  if (window.location.pathname.includes(APP_HUNT)) {
    return APP_HUNT
  } else if (window.location.pathname.includes(APP_BACKCOUNTRY)) {
    return APP_BACKCOUNTRY
  } else if (window.location.pathname.includes(APP_OFFROAD)) {
    return APP_OFFROAD
  } else {
    return ''
  }
}

function processTrail(trail) {
  const geo_json = {
    type: 'Feature',
    geometry: trail.geometry,
    properties: {
      'data:technical_rating': trail.attributes.technicalRating,
      color: '#0FF'
    }
  }
  return geo_json
}

function processRoute(route) {
  const linestring = polyline.toGeoJSON(route.route.geometry)
  const geo_json = {
    type: 'Feature',
    geometry: linestring,
    properties: {
      color: route.color,
      style: route.style
    }
  }
  return geo_json
}

function processTrack(track) {
  if (track.geo_json.properties.color) {
    const color = track.geo_json.properties.color
    if (Array.isArray(color)) {
      track.geo_json.properties.color = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`
    }
  }
  return track.geo_json
}

function addPathLayers(layerId, geo_json) {
  onyx.addSource(onyx.MAIN_VIEWPORT_ID, `${layerId}$definition`, JSON.stringify({
    type: 'geojson',
    data: geo_json
  }))
  onyx.addLayer(onyx.MAIN_VIEWPORT_ID, JSON.stringify({
    id: `${layerId}$casing`,
    type: 'line',
    source: `${layerId}$definition`,
    paint: {
      'line-color': '#FFF',
      'line-width': 8
    }
  }))
  onyx.addLayer(onyx.MAIN_VIEWPORT_ID, JSON.stringify({
    id: layerId,
    type: 'line',
    source: `${layerId}$definition`,
    paint: {
      'line-dasharray': [
        'match',
        [ 'get', 'style' ],
        'solid',
        [ 1 ],
        'dot',
        [ 1, 1 ],
        'dash',
        [ 2, 1 ],
        [ 1 ]
      ],
      "line-color": [
        "interpolate",
        ["linear"],
        ["zoom"],
        9,
        [
          "match",
          ["get", "data:technical_rating"],
          ["1", "2", "3"],
          "rgba(0, 166, 15, 1)",
          ["4", "5", "6"],
          "rgba(17, 91, 238, 1)",
          ["7", "8"],
          "rgba(26, 26, 26, 1)",
          ["9", "10"],
          "rgba(181, 18, 18, 1)",
          [ 'get', 'color' ]
        ],
        10,
        [
          "match",
          ["get", "data:technical_rating"],
          ["1", "2", "3"],
          "rgba(0, 166, 15, 0.7)",
          ["4", "5", "6"],
          "rgba(17, 91, 238, 0.7)",
          ["7", "8"],
          "rgba(26, 26, 26, 0.7)",
          ["9", "10"],
          "rgba(181, 18, 18, 0.7)",
          [ 'get', 'color' ]
        ],
        13,
        [
          "match",
          ["get", "data:technical_rating"],
          ["1", "2", "3"],
          "rgba(0, 166, 15, 0.5)",
          ["4", "5", "6"],
          "rgba(17, 91, 238, 0.5)",
          ["7", "8"],
          "rgba(26, 26, 26, 0.6)",
          ["9", "10"],
          "rgba(181, 18, 18, 0.5)",
          [ 'get', 'color' ]
        ],
        15,
        [
          "match",
          ["get", "data:technical_rating"],
          ["1", "2", "3"],
          "rgba(0, 166, 15, 0.5)",
          ["4", "5", "6"],
          "rgba(17, 91, 238, 0.5)",
          ["7", "8"],
          "rgba(26, 26, 26, 0.6)",
          ["9", "10"],
          "rgba(181, 18, 18, 0.5)",
          [ 'get', 'color' ]
        ]
      ],
      'line-width': 4
    }
  }))
}

function loadAllPaths() {
  onyx.removeLayer(onyx.MAIN_VIEWPORT_ID, 'all$paths$casing')
  onyx.removeLayer(onyx.MAIN_VIEWPORT_ID, 'all$paths')
  let geo_json = {
    type: 'FeatureCollection',
    features: [
    ]
  }
  const routes = markupService.getRoutes()
  for (const id in routes) {
    geo_json.features.push(processRoute(routes[id]))
  }
  const tracks = markupService.getTracks()
  for (const id in tracks) {
    geo_json.features.push(processTrack(tracks[id]))
  }
  const trails = supergraphService.getTrails()
  for (const id in trails) {
    geo_json.features.push(processTrail(trails[id]))
  }
  addPathLayers('all$paths', geo_json)
}

function fit(bounds, point) {
  return {
    min: { lon: Math.min(bounds.min.lon, point[0]), lat: Math.min(bounds.min.lat, point[1]) },
    max: { lon: Math.max(bounds.max.lon, point[0]), lat: Math.max(bounds.max.lat, point[1]) }
  }
}

function computeBounds(geo_json) {
  let bounds = {
    min: {
      lon: 180.0,
      lat: 90.0
    },
    max: {
      lon: -180.0,
      lat: -90.0
    }
  }

  if (geo_json.geometry.type == "LineString") {
    for (const point of geo_json.geometry.coordinates) {
      bounds = fit(bounds, point)
    }
  }

  if (geo_json.geometry.type == "MultiLineString") {
    for (const linestring of geo_json.geometry.coordinates) {
      for (const point of linestring) {
        bounds = fit(bounds, point)
      }
    }
  }

  return bounds
}

function toUnitVector(lon, lat) {
  const deg2rad = Math.PI / 180.0
  const theta = (lon + 180.0) * deg2rad
  const phi = (180.0 - lat + 90.0) * deg2rad
  return [ Math.cos(theta) * Math.sin(phi), Math.sin(theta) * Math.sin(phi), Math.cos(phi) ]
}

function dot(lhs, rhs) {
  let product = 0.0
  for (let i = 0; i < lhs.length; ++i) {
    product += lhs[i] * rhs[i]
  }
  return product
}

function globeDistance(lhs, rhs) {
  const u = toUnitVector(lhs.lon, lhs.lat)
  const v = toUnitVector(rhs.lon, rhs.lat)
  return EARTH_RADIUS_METERS * Math.acos(dot(u, v))
}

function clearFocusedPath() {
  onyx.removeLayer(onyx.MAIN_VIEWPORT_ID, 'focused$path$casing')
  onyx.removeLayer(onyx.MAIN_VIEWPORT_ID, 'focused$path')
  onyx.removeSource(onyx.MAIN_VIEWPORT_ID, 'focused$path$definition')
}

function focusOnPath(id, geo_json, flightTimeMS = 3000) {
  if (id == focusedPathId) {
    return
  }
  onyx.toggleLayer(onyx.MAIN_VIEWPORT_ID, 'all$paths$casing', 'none')
  onyx.toggleLayer(onyx.MAIN_VIEWPORT_ID, 'all$paths', 'none')

  const bounds = computeBounds(geo_json)

  onyx.toggleLayerGroup(onyx.MAIN_VIEWPORT_ID, 'extraneous', 'none')
  clearFocusedPath()
  addPathLayers('focused$path', geo_json)

  const destination = {
    lon: (bounds.min.lon + bounds.max.lon) / 2.0,
    lat: (bounds.min.lat + bounds.max.lat) / 2.0
  }
  const radius = 1.5 * globeDistance(bounds.min, bounds.max)
  const lookState = {
    focus: {
      lon: destination.lon,
      lat: destination.lat
    },
    heading: 0.0,
    pitch: 0.0,
    radius: radius
  }
  onyx.flyToLookState2D(onyx.MAIN_VIEWPORT_ID, lookState, flightTimeMS)

  focusedPathId = id
}

function focusOnTrail(id) {
  if (id == focusedPathId) {
    return
  }
  const trail = supergraphService.getTrails()[id]
  const geo_json = processTrail(trail)
  focusOnPath(id, geo_json)
}

function focusOnRoute(id) {
  if (id == focusedPathId) {
    return
  }
  const route = markupService.getRoutes()[id]
  const geo_json = processRoute(route)
  focusOnPath(id, geo_json)
}

function focusOnTrack(id) {
  if (id == focusedPathId) {
    return
  }
  const track = markupService.getTracks()[id]
  const geo_json = processTrack(track)
  focusOnPath(id, geo_json)
}

function toLonLat(arr) {
  return { lon: arr[0], lat: arr[1] }
}

function insertToLineString(merged, pending) {
  const first = toLonLat(merged.coordinates[0])
  const last = toLonLat(merged.coordinates[merged.coordinates.length - 1])
  const pendingFirst = toLonLat(pending[0])
  const pendingLast = toLonLat(pending[pending.length - 1])
  const distances = [
    globeDistance(last, pendingFirst),  // last to first
    globeDistance(last, pendingLast),   // last to last
    globeDistance(first, pendingFirst), // first to first
    globeDistance(first, pendingLast)   // first to last
  ]

  const distance = Math.min(...distances)
  if (distance == distances[0]) {         // last to first => add in order to end of list
    merged.coordinates = merged.coordinates.concat(pending)
  } else if (distance == distances[1]) {  // last to last => add in reverse order to end of list
    merged.coordinates = merged.coordinates.concat(pending.reverse())
  } else if (distance == distances[2]) {  // first to first => add in reverse order to beginning of list
    merged.coordinates = pending.reverse().concat(merged.coordinates)
  } else if (distance == distances[3]) {  // first to last => add in order to beginning of list
    merged.coordinates = pending.concat(merged.coordinates)
  }
}

function mergeToLineString(multilinestring) {
  let linestring = {
    type: "LineString",
    coordinates: []
  }

  let clone = JSON.parse(JSON.stringify(multilinestring)) // hacky deep clone

  // copy over the first linestring
  {
    const coords = clone.coordinates[0]
    for (const j in coords) {
      linestring.coordinates.push(coords[j])
    }
    clone.coordinates.splice(0, 1)  // remove the first linestring
  }

  // copy over remaining linestrings
  while (clone.coordinates.length > 0) {
    let first = toLonLat(linestring.coordinates[0])
    let last = toLonLat(linestring.coordinates[linestring.coordinates.length - 1])
    let c = 0
    let shortestDist = Number.MAX_VALUE
    for (const i in clone.coordinates) {
      const coords = clone.coordinates[i]
      const candidateFirst = toLonLat(coords[0])
      const candidateLast = toLonLat(coords[coords.length - 1])
      const distances = [
        globeDistance(last, candidateFirst),
        globeDistance(last, candidateLast),
        globeDistance(first, candidateFirst),
        globeDistance(first, candidateLast)
      ]
      const dist = Math.min(...distances)
      if (dist < shortestDist || (dist == shortestDist && globeDistance(candidateFirst, candidateLast) == 0.0)) {
        c = i
        shortestDist = dist
      }
    }
    insertToLineString(linestring, clone.coordinates[c])
    clone.coordinates.splice(c, 1)
  }

  return linestring
}

async function initTerrainX(event) {
  const zoomInScalar = 0.55
  const zoomOutScalar = 1.81818181818
  const deltaHeading = 45.0
  const rotateDeltaPitch = 11.25
  const animationDuration = 300

  onXUserJwt = event.detail.onXUserJwt

  // The Navigo router wasn't initialized yet, and doesn't seem to support any kind of awaiting
  // So, falling back to getting it directly through the window
  const currApp = computeApp()

  await initOnyx()

  // Populate map with state defaults for slope aspect rose only on BC (since it's working different than others)
  if (currApp === APP_BACKCOUNTRY) {
    document.dispatchEvent(new CustomEvent('slope-aspect-rose-changed'))
  }

  loadStyleForApp(currApp)

  initLayers()

  initCameraState()

  initTrailId()

  // update url hash when camera state changes
  onyx.addEventCallback(onyx.EventType.CAMERA_STOPPED, () => {
    const state = onyx.getCameraState(onyx.MAIN_VIEWPORT_ID)
    history.replaceState(
      null,
      null,
      `#${state.position.lat}/${state.position.lon}/${state.position.elevation}/${state.heading}/${state.pitch}`
    )
  })

  const canvas = document.getElementById('canvas')
  appService.canvas = canvas

  recording.init(onyx, canvas)
  onyx.addEventCallback(onyx.EventType.CAMERA_STOPPED, () => {
    if (recording.isRecording()) recording.stopAndDownload()
  })

  canvas.addEventListener('mousemove', (e) => {
    mousePosition.x = e.offsetX
    mousePosition.y = e.offsetY

    // Try to update the viewshed position, if it's enabled.
    if (viewshedEnabled && followCursor) {
      viewshedLayer.update(mousePosition.x, mousePosition.y)
    }
  })

  // listen for viewshed lock and unlock position
  let timePrevRightClick = null
  canvas.addEventListener('mouseup', (e) => {
    const isRightClick = e?.button === 2
    if (viewshedEnabled && isRightClick) {
      const diff = Date.now() - timePrevRightClick
      // check that time between clicks is <500ms (generally max time between clicks to be considered a double click)
      if (diff < 500) {
        followCursor = !followCursor
        if (followCursor) {
          viewshedLayer.update(e.offsetX, e.offsetY)
        }

        setViewshedState(followCursor ? 'enabled' : 'locked')

        marketing.logEvent(`viewshed_${followCursor ? 'unlocked' : 'locked'}`)
      }

      timePrevRightClick = Date.now()
    }
  })

  document.addEventListener('slope-aspect-rose-changed', (event) => {
    // This event uses a different paradigm than the others
    // State is handled all in the component, and we only apply it to the map here
    const currState = stateService.getState('slopeAspectDegrees')
    slopeAspectLayer.update(currState.map(r => [r.start, r.end]))
  })

  document.addEventListener('slope-aspect-changed', (event) => {
    const aspectValues = {
      min: event.detail.startDegrees,
      max: event.detail.endDegrees,
    }
    stateService.setState('slopeAspectDegrees', aspectValues)
    slopeAspectLayer.update([aspectValues.min, aspectValues.max])
  })

  /**
   * Height Band
   */
  document.addEventListener('on-elevation-band-toggled', (event) => {
    const bandToggled = event.detail
    stateService.setState('heightBandEnabled', bandToggled)
    elevationRangeLayer.toggle(bandToggled)
    if (bandToggled) {
      updateElevationRangeFromState()
    } else {
      elevationRangeLayer.update(0, 14000 * FEET_TO_METERS)
    }
  })

  document.addEventListener('elevation-changed', async (event) => {
    const heightDetailsFeet = {
      min: event.detail.minElevation,
      max: event.detail.maxElevation,
    }
    stateService.setState('heightBandFeet', heightDetailsFeet)
    elevationRangeLayer.update(
      heightDetailsFeet.min * FEET_TO_METERS,
      heightDetailsFeet.max * FEET_TO_METERS
    )
  })

  /**
   * Zoom
   */
  document.addEventListener('zoom-in', () => {
    const focus = onyx.viewportToLonLatElevation(onyx.MAIN_VIEWPORT_ID, {
      x: canvas.clientWidth / 2,
      y: canvas.clientHeight / 2,
    })
    onyx.zoom(onyx.MAIN_VIEWPORT_ID, focus, zoomInScalar, animationDuration)
  })

  document.addEventListener('zoom-out', () => {
    const focus = onyx.viewportToLonLatElevation(onyx.MAIN_VIEWPORT_ID, {
      x: canvas.clientWidth / 2,
      y: canvas.clientHeight / 2,
    })
    onyx.zoom(onyx.MAIN_VIEWPORT_ID, focus, zoomOutScalar, animationDuration)
  })

  document.addEventListener('zoom-on', (event) => {
    const focus = onyx.viewportToLonLatElevation(
      onyx.MAIN_VIEWPORT_ID,
      mousePosition
    )
    onyx.zoom(onyx.MAIN_VIEWPORT_ID, focus, zoomInScalar, animationDuration)
  })

  document.addEventListener('rotate-right', () => {
    const viewportSize = onyx.getViewportSize(onyx.MAIN_VIEWPORT_ID)
    const screenCenter = { x: viewportSize.x / 2.0, y: viewportSize.y / 2.0 }
    const focusPoint = onyx.viewportToLonLatElevation(
      onyx.MAIN_VIEWPORT_ID,
      screenCenter
    )
    const deltaPitch = 0
    onyx.orbit(
      onyx.MAIN_VIEWPORT_ID,
      focusPoint,
      deltaHeading,
      deltaPitch,
      1,
      animationDuration
    )
  })

  document.addEventListener('rotate-left', () => {
    const viewportSize = onyx.getViewportSize(onyx.MAIN_VIEWPORT_ID)
    const screenCenter = { x: viewportSize.x / 2.0, y: viewportSize.y / 2.0 }
    const focusPoint = onyx.viewportToLonLatElevation(
      onyx.MAIN_VIEWPORT_ID,
      screenCenter
    )
    const deltaPitch = 0
    onyx.orbit(
      onyx.MAIN_VIEWPORT_ID,
      focusPoint,
      -deltaHeading,
      deltaPitch,
      1,
      animationDuration
    )
  })

  document.addEventListener('pitch-up', () => {
    const viewportSize = onyx.getViewportSize(onyx.MAIN_VIEWPORT_ID)
    const screenCenter = { x: viewportSize.x / 2.0, y: viewportSize.y / 2.0 }
    const focusPoint = onyx.viewportToLonLatElevation(
      onyx.MAIN_VIEWPORT_ID,
      screenCenter
    )
    const deltaHeading = 0
    onyx.orbit(
      onyx.MAIN_VIEWPORT_ID,
      focusPoint,
      deltaHeading,
      rotateDeltaPitch,
      1,
      animationDuration
    )
  })

  document.addEventListener('pitch-down', () => {
    const viewportSize = onyx.getViewportSize(onyx.MAIN_VIEWPORT_ID)
    const screenCenter = { x: viewportSize.x / 2.0, y: viewportSize.y / 2.0 }
    const focusPoint = onyx.viewportToLonLatElevation(
      onyx.MAIN_VIEWPORT_ID,
      screenCenter
    )
    const deltaHeading = 0
    onyx.orbit(
      onyx.MAIN_VIEWPORT_ID,
      focusPoint,
      deltaHeading,
      -rotateDeltaPitch,
      1,
      animationDuration
    )
  })

  // Reset the camera back to North heading and no pitch
  document.addEventListener('orient-control', () => {
    const DEGREES_TO_RADIANS = Math.PI / 180.0
    const RADIANS_TO_DEGRESS = 180.0 / Math.PI
    const state = onyx.getCameraState(onyx.MAIN_VIEWPORT_ID)
    const heading = state.heading * DEGREES_TO_RADIANS
    const pitch = state.pitch * DEGREES_TO_RADIANS
    const inZeroToPi = 0.0 <= heading && heading <= Math.PI
    const deltaHeading = (inZeroToPi ? -heading : 2 * Math.PI - heading) * RADIANS_TO_DEGRESS
    const deltaPitch = -pitch * RADIANS_TO_DEGRESS
    const bigger = Math.max(Math.abs(deltaHeading), Math.abs(deltaPitch))
    const durationMS = 1000.0 + bigger * 25.0
    const screenCenter = { x: canvas.clientWidth / 2, y: canvas.clientHeight / 2 }
    const focus = onyx.viewportToLonLatElevation(onyx.MAIN_VIEWPORT_ID, screenCenter)
    onyx.orbit(
      onyx.MAIN_VIEWPORT_ID,
      focus,
      deltaHeading,
      deltaPitch,
      1,
      durationMS
    )
  })

  /**
   * Markups
   */
  document.addEventListener('on-waypoint-create', (event) => {
    const screenPoint = { x: event.detail.offsetX, y: event.detail.offsetY }
    const lle = onyx.viewportToLonLatElevation(onyx.MAIN_VIEWPORT_ID, screenPoint)

    // create waypoint in service
    const waypoint = markupService.createWaypoint(lle)

    // add waypoint to map
    onyx.addWaypoint(
      waypoint.uuid,
      { lon: lle.lon, lat: lle.lat },
      { r: 1.0, g: 0.2, b: 0.0, a: 1.0 }
    )
  })

  document.addEventListener('on-waypoint-remove', (event) => {
    const uuid = event.detail.uuid

    // remove waypoint from service
    markupService.removeWaypoint(uuid)

    // remove waypoint from map
    onyx.deleteWaypoint(uuid)
  })

  document.addEventListener('on-open-flyby', (event) => {
    onyx.toggleLayerGroup(onyx.MAIN_VIEWPORT_ID, 'extraneous', 'none')
    loadAllPaths()
  })

  document.addEventListener('on-close-flyby', (event) => {
    clearFocusedPath()
    onyx.toggleLayer(onyx.MAIN_VIEWPORT_ID, 'all$paths$casing', 'none')
    onyx.toggleLayer(onyx.MAIN_VIEWPORT_ID, 'all$paths', 'none')
    onyx.toggleLayerGroup(onyx.MAIN_VIEWPORT_ID, 'extraneous', 'visible')
  })

  document.addEventListener('on-trail-focus', (event) => {
    focusOnTrail(event.detail.id)
  })

  document.addEventListener('on-route-focus', (event) => {
    focusOnRoute(event.detail.id)
  })

  document.addEventListener('on-track-focus', (event) => {
    focusOnTrack(event.detail.id)
  })

  document.addEventListener('on-trail-unfocus', (event) => {
    onyx.toggleLayer(onyx.MAIN_VIEWPORT_ID, 'focused$path', 'none')
    onyx.toggleLayer(onyx.MAIN_VIEWPORT_ID, 'focused$path$casing', 'none')
    focusedPathId = null
    loadAllPaths()
  })

  document.addEventListener('on-route-unfocus', (event) => {
    onyx.toggleLayer(onyx.MAIN_VIEWPORT_ID, 'focused$path', 'none')
    onyx.toggleLayer(onyx.MAIN_VIEWPORT_ID, 'focused$path$casing', 'none')
    focusedPathId = null
    loadAllPaths()
  })

  document.addEventListener('on-track-unfocus', (event) => {
    onyx.toggleLayer(onyx.MAIN_VIEWPORT_ID, 'focused$path', 'none')
    onyx.toggleLayer(onyx.MAIN_VIEWPORT_ID, 'focused$path$casing', 'none')
    focusedPathId = null
    loadAllPaths()
  })

  document.addEventListener('on-flyby-set-time', (event) => {
    flybyDuration = event.detail.time
  })

  document.addEventListener('on-flyby-set-guides', (event) => {
    flybyRadiusGuide = event.detail.radius
    flybyPitchGuide = event.detail.pitch
  })

  document.addEventListener('on-trail-flyby', (event) => {
    const id = event.detail.id
    focusOnTrail(id)
    const trail = supergraphService.getTrails()[id]
    let path = processTrail(trail)
    if (path.geometry.type == "MultiLineString") {
      path.geometry = mergeToLineString(path.geometry)
    }
    onyx.flybyGeoJson(onyx.MAIN_VIEWPORT_ID, JSON.stringify(path), flybyDuration, flybyRadiusGuide, flybyPitchGuide)
  })

  document.addEventListener('on-route-flyby', (event) => {
    const id = event.detail.id
    focusOnRoute(id)
    const route = markupService.getRoutes()[id]
    const path = processRoute(route)
    onyx.flybyGeoJson(onyx.MAIN_VIEWPORT_ID, JSON.stringify(path), flybyDuration, flybyRadiusGuide, flybyPitchGuide)
  })

  document.addEventListener('on-track-flyby', (event) => {
    const id = event.detail.id
    focusOnTrack(id)
    const track = markupService.getTracks()[id]
    const path = processTrack(track)
    onyx.flybyGeoJson(onyx.MAIN_VIEWPORT_ID, JSON.stringify(path), flybyDuration, flybyRadiusGuide, flybyPitchGuide)
  })

  document.addEventListener('on-go-to-coordinates', (event) => {
    flyTo(event.detail.longitude, event.detail.latitude)
  })

  document.addEventListener('on-viewshed-toggled', (event) => {
    viewshedEnabled = event.detail
    followCursor = true
    viewshedLayer.toggle(viewshedEnabled)
  })

  /**
   * Slope angle
   */
  document.addEventListener('on-slope-angle-toggled', (event) => {
    const angleToggled = event.detail
    stateService.setState('slopeAngleEnabled', angleToggled)
    slopeAngleLayer.toggle(angleToggled)
    if (angleToggled) {
      updateSlopeAngleFromState()
    } else {
      slopeAngleLayer.update(0, 90)
    }
  })

  document.addEventListener('slope-angle-changed', (event) => {
    const angleValues = {
      min: event.detail.minSlopeDegrees,
      max: event.detail.maxSlopeDegrees,
    }
    stateService.setState('slopeAngleDegrees', angleValues)
    slopeAngleLayer.update(angleValues.min, angleValues.max)
  })

  document.addEventListener('on-slope-aspect-toggled', (event) => {
    const aspectToggled = event.detail
    stateService.setState('slopeAspectEnabled', aspectToggled)
    slopeAspectLayer.toggle(aspectToggled)
    if (aspectToggled) {
      updateSlopeAspectFromState()
    } else {
      slopeAspectLayer.update([[0, 360]])
    }
  })

  document.addEventListener('on-intersect-toggled', (event) => {
    const intersectToggled = event.detail
    stateService.setState('intersectEnabled', intersectToggled)
    intersectLayer.toggle(intersectToggled)
    if (!intersectToggled) {
      const elevationEnabled = stateService.getState('heightBandEnabled')
      const slopeAngleEnabled = stateService.getState('slopeAngleEnabled')
      const slopeAspectEnabled = stateService.getState('slopeAspectEnabled')
      if (elevationEnabled) elevationRangeLayer.toggle(true)
      if (slopeAngleEnabled) slopeAngleLayer.toggle(true)
      if (slopeAspectEnabled) slopeAspectLayer.toggle(true)
    }
  })

  document.addEventListener('intersect-changed', (event) => {
    stateService.setState('intersectInverted', event.detail.inverted)
    intersectLayer.update(event.detail.inverted)
  })

  document.addEventListener('basemap-toggle', () => {
    let basemapIdx = stateService.getState('basemapIdx')
    basemapIdx++
    if (basemapIdx >= basemaps.length) {
      basemapIdx = 0
    }
    stateService.setState('basemapIdx', basemapIdx)
    localStorage.setItem('lastBasemapIdx', JSON.stringify(basemapIdx))
    selectBasemap(basemapIdx)
  })

  // fetch markups
  fetchWaypoints(currApp)
  fetchRoutes(currApp)
  fetchTracks(currApp)
  fetchTrails(currApp)
}

main()
